export default function (axios) {
  return {
    getReports(reportName, params) {
      return axios
        .get(`/modules/transact/reports/${reportName}`, params)
        .then((res) => res.data)
    },

    getAnalyticsReports(params = {}) {
      return axios
        .get('/modules/analytics/reports', params)
        .then((res) => res.data)
    },

    getAnalyticsReport(reportName, params) {
      return axios
        .get(`/modules/analytics/reports/${reportName}`, params)
        .then((res) => res.data)
    },
  }
}
